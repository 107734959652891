import {
  Box,
  Menu as ChakraMenu,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  List,
  ListItem,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { Facebook, Instagram, Mail, X } from "react-feather";
import { useTranslation as useTranslationi18n } from "next-i18next";
import { isAfter } from "date-fns";

import Menu from "@/components/Layout/Menu";
import ButtonAnimated from "@/components/common/ButtonAnimated";
import checkIfFullFridge from "@/utils/fullFridge";
import { getFlagShort, languagesShort } from "@/utils/language";
import StickyHeader from "./StickyHeader";
import {
  GS_FACEBOOK_URL,
  GS_INSTAGRAM_URL,
  FF_FACEBOOK_URL,
  FF_INSTAGRAM_URL,
} from "../../constants";

export default function Layout({ children }) {
  const { pageRef } = StickyHeader();
  const { publicRuntimeConfig } = getConfig();
  const { t } = useTranslation();
  const { i18n } = useTranslationi18n();
  const router = useRouter();
  const { data: session, status } = useSession();
  const [cookie, setCookie] = useCookies(["NEXT_LOCALE"]);
  const { locale, asPath } = router;
  const userSubscriptionEnded = useMemo(
    () =>
      session?.user?.lastSubscription?.end
        ? isAfter(new Date(), new Date(session?.user?.lastSubscription?.end))
        : null,
    [session]
  );
  const [showSubscriptionInfo, setShowSubscriptionInfo] = useState(true);

  const languages = useMemo(
    () =>
      languagesShort.reduce(
        (acc, curr) => (
          (acc[curr] = {
            icon: getFlagShort(curr),
          }),
          acc
        ),
        {}
      ),
    []
  );
  const currentLanguage = languages[locale];

  const switchLanguage = (locale) => async () => {
    if (cookie.NEXT_LOCALE !== locale)
      setCookie("NEXT_LOCALE", locale, { path: "/" });

    router.push(asPath, asPath, { locale });
  };

  return (
    <>
      <Box
        bg={checkIfFullFridge() ? "green.500" : "red.500"}
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={1001}
      >
        <Flex
          justify="space-between"
          align="center"
          className="main-container"
          mx="auto"
          pl={{ base: "15px", sm: "50px", xl: 0 }}
          pr={{ base: "15px", sm: "50px", xl: 0 }}
          pt="10px"
          pb="10px"
          minH={{ base: "66px", sm: "78px" }}
        >
          <Box
            bg="white"
            borderRadius="10px"
            py="8px"
            px="10px"
            onClick={() => router.push("/")}
            cursor="pointer"
          >
            <Image
              src={
                checkIfFullFridge()
                  ? `${publicRuntimeConfig.basePath}/logo-food.svg`
                  : `${publicRuntimeConfig.basePath}/logo.svg`
              }
              alt=""
              w={{ base: "140px", sm: "196px" }}
            />
          </Box>
          <Flex align="center">
            <Flex gap={{ base: 0, sm: "10px" }} align="center">
              {!checkIfFullFridge() && <Menu />}

              <ChakraMenu>
                <MenuButton as="a">
                  <Image
                    src={`${publicRuntimeConfig.basePath}/${currentLanguage?.icon}`}
                    alt=""
                    w="25px"
                    d="block"
                    m="0.4rem 0.8rem"
                  />
                </MenuButton>
                <MenuList minW={0}>
                  {Object.keys(languages).map((key) => (
                    <MenuItem
                      key={key}
                      onClick={switchLanguage(key)}
                      isDisabled={key === locale}
                    >
                      <Image
                        src={`${publicRuntimeConfig.basePath}/${languages[key]?.icon}`}
                        alt=""
                        w="25px"
                      />
                    </MenuItem>
                  ))}
                </MenuList>
              </ChakraMenu>
            </Flex>
          </Flex>
        </Flex>
      </Box>

      {status === "authenticated" &&
        userSubscriptionEnded &&
        showSubscriptionInfo && (
          <Alert
            status="info"
            position={"fixed"}
            zIndex={1001}
            rounded="none"
            justifyContent={"center"}
            opacity={0.9}
            flexDir={{ base: "column", sm: "row" }}
            alignItems="center"
            gap={{ base: "10px", sm: "0" }}
          >
            <AlertIcon />
            <AlertTitle>{t("garage.unactive")}</AlertTitle>
            <ButtonAnimated
              text={t("garage.renewSubscription")}
              size="sm"
              onClick={() => router.push("/subscription")}
            />
            <Icon
              as={X}
              w={5}
              h={5}
              alignSelf="flex-start"
              position="absolute"
              right={1}
              top={1}
              cursor="pointer"
              onClick={() => setShowSubscriptionInfo(false)}
              _hover={{
                transform: "scale(1.1)",
              }}
              transition="all .25s ease-in-out"
            />
          </Alert>
        )}

      <Box ref={pageRef}>{children}</Box>

      <Box py={{ base: "30px", sm: "50px", xl: "100px" }} bg="gray.200">
        <Box
          className="main-container"
          px={{ base: "15px", sm: "50px", xl: 0 }}
          mx="auto"
        >
          <Flex
            gap="40px"
            justify="space-between"
            flexWrap={{ base: "wrap", xl: "nowrap" }}
          >
            <Box>
              <Heading
                size="md"
                variant="gray"
                mt={0}
                fontSize={{ base: "18px", sm: "20px" }}
              >
                {t("home.informationForYou")}
              </Heading>
              <Flex gap="40px">
                <List spacing="5px">
                  <ListItem>
                    <Link href={`${publicRuntimeConfig.basePath}/about`}>
                      {t("home.howWorks")}
                    </Link>
                  </ListItem>
                  {!checkIfFullFridge() && (
                    <ListItem>
                      <Link
                        href={`${publicRuntimeConfig.basePath}/terms-and-conditions`}
                      >
                        {t("regulations")}
                      </Link>
                    </ListItem>
                  )}
                  {!checkIfFullFridge() && (
                    <ListItem>
                      <Link
                        href={`${publicRuntimeConfig.basePath}/privacy-policy`}
                      >
                        {t("privacyPolicy")}
                      </Link>
                    </ListItem>
                  )}
                  <ListItem>
                    <Link
                      href={
                        checkIfFullFridge()
                          ? `${publicRuntimeConfig.basePath}/terms-and-conditions`
                          : "https://fullfridge.app/terms-and-conditions"
                      }
                    >
                      {!checkIfFullFridge()
                        ? t("regulationsFullFridge")
                        : t("regulations")}
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      href={
                        checkIfFullFridge()
                          ? `${publicRuntimeConfig.basePath}/privacy-policy`
                          : "https://fullfridge.app/privacy-policy"
                      }
                    >
                      {!checkIfFullFridge()
                        ? t("privacyPolicyFullFridge")
                        : t("privacyPolicy")}
                    </Link>
                  </ListItem>
                </List>
              </Flex>
            </Box>
            <Box>
              <Heading
                size="md"
                variant="gray"
                mt={0}
                fontSize={{ base: "18px", sm: "20px" }}
              >
                {t("home.joinUs")}
              </Heading>
              <Flex gap="20px">
                <Flex gap="20px" direction="column">
                  <Image
                    src={`${publicRuntimeConfig.basePath}/logo.svg`}
                    alt=""
                    w="140px"
                  />
                  <Stack direction="row" spacing="10px">
                    <Link href={GS_FACEBOOK_URL} target="_blank" lineHeight={0}>
                      <Icon
                        as={Facebook}
                        w={{ base: "30px", sm: "50px" }}
                        h={{ base: "30px", sm: "50px" }}
                      />
                    </Link>
                    <Link
                      href={GS_INSTAGRAM_URL}
                      target="_blank"
                      lineHeight={0}
                    >
                      <Icon
                        as={Instagram}
                        w={{ base: "30px", sm: "50px" }}
                        h={{ base: "30px", sm: "50px" }}
                      />
                    </Link>
                  </Stack>
                </Flex>
                <Flex gap="20px" direction="column">
                  <Box flex={1}>
                    <Image
                      src={`${publicRuntimeConfig.basePath}/logo-food.svg`}
                      alt=""
                      w="140px"
                    />
                  </Box>
                  <Stack direction="row" spacing="10px">
                    <Link href={FF_FACEBOOK_URL} target="_blank" lineHeight={0}>
                      <Icon
                        as={Facebook}
                        w={{ base: "30px", sm: "50px" }}
                        h={{ base: "30px", sm: "50px" }}
                      />
                    </Link>
                    <Link
                      href={FF_INSTAGRAM_URL}
                      target="_blank"
                      lineHeight={0}
                    >
                      <Icon
                        as={Instagram}
                        w={{ base: "30px", sm: "50px" }}
                        h={{ base: "30px", sm: "50px" }}
                      />
                    </Link>
                  </Stack>
                </Flex>
              </Flex>
              <Heading
                mt={{ base: "40px", sm: "100px" }}
                size="md"
                variant="gray"
                fontSize={{ base: "18px", sm: "20px" }}
              >
                {t("home.contact")}
              </Heading>
              <Stack direction="row" spacing="10px" alignItems="center">
                <Icon as={Mail} w="25px" h="25px" color="gray.500" />
                <Link
                  fontSize="18px"
                  href={
                    !checkIfFullFridge()
                      ? "mailto:kontakt@garazowewyprzedaze.pl"
                      : "mailto:office@fullfridge.app"
                  }
                >
                  {!checkIfFullFridge()
                    ? "kontakt@garazowewyprzedaze.pl"
                    : "office@fullfridge.app"}
                </Link>
              </Stack>
            </Box>

            {/* TODO: Show when mobile app will be ready */}
            {/* <Box>
              <Heading
                size="md"
                variant="gray"
                mt={0}
                fontSize={{ base: "18px", sm: "20px" }}
              >
                {t("home.freeApp")}
              </Heading>
              <SimpleGrid
                columns={{ base: 1, sm: 2 }}
                spacing={{ base: "30px", sm: "40px" }}
              >
                <Flex justify={{ base: "center", sm: "flex-end" }}>
                  <Box w="210px">
                    <a
                      href={
                        !checkIfFullFridge()
                          ? "https://garazowewyprzedaze.pl/download/android"
                          : "https://fullfridge.app/download/android"
                      }
                      rel="noopener"
                    >
                      <Image
                        src={
                          i18n?.language == "pl"
                            ? `${publicRuntimeConfig.basePath}/google-play.png`
                            : `${publicRuntimeConfig.basePath}/google-play-en.png`
                        }
                        alt=""
                        d="block"
                        mx="auto"
                        w={{ base: "150px", sm: "205px" }}
                      />
                    </a>
                    <Image
                      src={`${publicRuntimeConfig.basePath}/${
                        checkIfFullFridge() ? "ff" : "gs"
                      }_qr_android.svg`}
                      alt=""
                      w={{ base: "160px", sm: "100%" }}
                      d="block"
                      mx="auto"
                      mt="27.5px"
                    />
                  </Box>
                </Flex>
                <Flex justify={{ base: "center", sm: "flex-start" }}>
                  <Box w="210px">
                    <a
                      href={
                        !checkIfFullFridge()
                          ? "https://garazowewyprzedaze.pl/download/ios"
                          : "https://fullfridge.app/download/ios"
                      }
                      rel="noopener"
                    >
                      <Image
                        src={
                          i18n?.language == "pl"
                            ? `${publicRuntimeConfig.basePath}/app-store.png`
                            : `${publicRuntimeConfig.basePath}/app-store-en.png`
                        }
                        alt=""
                        d="block"
                        mx="auto"
                        w={{ base: "150px", sm: "205px" }}
                      />
                    </a>
                    <Image
                      src={`${publicRuntimeConfig.basePath}/${
                        checkIfFullFridge() ? "ff" : "gs"
                      }_qr_ios.svg`}
                      alt=""
                      w={{ base: "160px", sm: "100%" }}
                      d="block"
                      mx="auto"
                      mt="20px"
                    />
                  </Box>
                </Flex>
              </SimpleGrid>
            </Box> */}
          </Flex>
          <Flex
            gap={{ base: "10px", sm: "40px" }}
            mt="50px"
            direction={{ base: "column", sm: "row" }}
            align={{ base: "center", md: "flex-start" }}
          >
            <Box
              as="a"
              d="block"
              href="https://copernicusapps.com/"
              target="_blank"
              w="130px"
            >
              <Image
                src={`${publicRuntimeConfig.basePath}/logo-copernicus.svg`}
                alt=""
                w="100%"
              />
            </Box>
            <Text
              fontSize="12px"
              color="gray.600"
              flex={1}
              textAlign={{ base: "center", sm: "justify" }}
            >
              {t("copernicusInfo")}
            </Text>
          </Flex>
        </Box>
      </Box>
    </>
  );
}
