/*
  Color themes, breakpoints, public
  keys, and other app-wide constants.
*/

export const GARAGE_MONTHLY_PRICE = 3;

export const BASIC_AMOUNT_OF_FREE_MONTHS = 6;

export const GS_FACEBOOK_URL = "https://www.facebook.com/garazowewyprzedazepl";
export const GS_INSTAGRAM_URL = "https://www.instagram.com/garazowewyprzedaze";

export const FF_FACEBOOK_URL = "https://www.facebook.com/fullfridgepl/";
export const FF_INSTAGRAM_URL = "https://www.instagram.com/fullfridge.app ";
