import React from "react";
import {
  Avatar,
  Box,
  Flex,
  Icon as IconChakra,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { signOut, useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { LogOut, MessageCircle, Settings, User } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faHeart } from "@fortawesome/free-solid-svg-icons";
import Icon from "@mdi/react";
import { mdiGarageVariant } from "@mdi/js";
import useSWR from "swr";

import ButtonAnimated from "@/components/common/ButtonAnimated";

const MenuComponent = (): React.ReactElement => {
  const { t } = useTranslation();
  const router = useRouter();
  const { status, data: session } = useSession();
  const { publicRuntimeConfig } = getConfig();
  const { data: unreadChatMessages } = useSWR(
    status === "authenticated" ? `/chat/messages/unread` : null,
    null,
    { refreshInterval: 10000 }
  );

  return (
    <Flex gap={{ base: "5px", sm: "10px" }} align="center">
      {status === "unauthenticated" ? (
        <>
          <ButtonAnimated
            variant="green"
            size="sm"
            onClick={() => router.push("/signin")}
            text={t("signin.login")}
            display={{ base: "none", sm: "flex" }}
          />
          <IconChakra
            as={User}
            color="white"
            w="20px"
            h="20px"
            verticalAlign="-4px"
            onClick={() => router.push("/signin")}
            display={{ base: "block", sm: "none" }}
          />
        </>
      ) : (
        <>
          <Box as="a" p="3.5px" lineHeight={0} position="relative">
            <IconChakra
              as={MessageCircle}
              color="white"
              w="25px"
              h="25px"
              onClick={() => router.push("/chat")}
              _hover={{
                opacity: 0.7,
              }}
              transition="all .25s ease-in-out"
            />
          </Box>

          {unreadChatMessages?.amount > 0 && (
            <Box ml={-4} mb={6} pl={1} pr={1} rounded="md" bgColor={"blue.500"}>
              <Text fontWeight="bold" color={"white"} fontSize="sm">
                {unreadChatMessages?.amount}
              </Text>
            </Box>
          )}

          <Menu isLazy>
            <MenuButton>
              <Avatar
                bg="red.500"
                size="sm"
                src={
                  session?.user?.profile?.picture
                    ? `${publicRuntimeConfig.apiUrl}/uploads/${session?.user?.profile?.picture}`
                    : ""
                }
                icon={<IconChakra as={User} color="white" w="25px" h="25px" />}
              />
            </MenuButton>
            <MenuList>
              <>
                <MenuItem
                  icon={
                    <IconChakra
                      color="blue.500"
                      w="20px"
                      h="20px"
                      verticalAlign="-4px"
                    >
                      <Icon path={mdiGarageVariant} />
                    </IconChakra>
                  }
                  onClick={() => router.push("/garage")}
                >
                  {t("myGarage")}
                </MenuItem>
                <MenuItem
                  icon={
                    <IconChakra
                      color="yellow.500"
                      w="20px"
                      h="20px"
                      verticalAlign="-4px"
                    >
                      <FontAwesomeIcon icon={faStar} />
                    </IconChakra>
                  }
                  onClick={() => router.push("/followed")}
                >
                  {t("favoriteGarages")}
                </MenuItem>
                <MenuItem
                  icon={
                    <IconChakra
                      color="green.500"
                      w="20px"
                      h="20px"
                      verticalAlign="-4px"
                    >
                      <FontAwesomeIcon icon={faHeart} />
                    </IconChakra>
                  }
                  onClick={() => router.push("/favorites")}
                >
                  {t("favoriteProducts")}
                </MenuItem>
                <MenuItem
                  icon={
                    <IconChakra
                      as={Settings}
                      color="red.500"
                      w="20px"
                      h="20px"
                      verticalAlign="-4px"
                    />
                  }
                  onClick={() => router.push("/profile")}
                >
                  {t("settings")}
                </MenuItem>
                <MenuItem
                  icon={
                    <IconChakra
                      as={LogOut}
                      color="blue.500"
                      w="20px"
                      h="20px"
                      verticalAlign="-4px"
                    />
                  }
                  onClick={() => signOut()}
                >
                  {t("logOut")}
                </MenuItem>
              </>
            </MenuList>
          </Menu>
        </>
      )}
      <ButtonAnimated
        ml="5px"
        variant="blue"
        size="sm"
        onClick={() =>
          router.push(
            session?.user?.id
              ? session?.user?.garages[0]?.id
                ? "/add-product"
                : "/garage"
              : "/add-product"
          )
        }
        text={
          session?.user?.id
            ? session?.user?.garages[0]?.id
              ? t("home.addOffer")
              : t("home.addGarage")
            : t("home.addOffer")
        }
        display={{ base: "none", sm: "flex" }}
      />
    </Flex>
  );
};

export default MenuComponent;
